.form-vehiculos {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-vehiculos h2 {
  margin-bottom: 20px;
}

.form-vehiculos form {
  width: 100%;
  max-width: 400px;
}

.form-vehiculos div {
  margin-bottom: 10px;
}

.form-vehiculos label {
  display: block;
  margin-bottom: 5px;
}

.form-vehiculos input,
.form-vehiculos select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.form-vehiculos button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.form-vehiculos .error {
  color: red;
  margin-bottom: 10px;
}